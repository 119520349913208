<template>
  <div class="view-outer">
    <div class="view-inner">
      <h1>简易Hash计算器</h1>

      <el-input
        type="textarea"
        v-model="inputText"
        :autosize="{ minRows: 8, maxRows: 12 }"
        class="input-text"
        placeholder="在此输入明文"
      />

      <el-button slot="append" @click="handleClickCompare" class="btn-compare">
        比较
      </el-button>

      <el-input
        placeholder="计算结果"
        v-model="result"
        readonly
        class="input-with-select"
      >
        <el-select
          v-model="algorithm"
          slot="prepend"
          placeholder="请选择"
          class="el-select-algorithm"
        >
          <el-option label="MD5" value="md5"></el-option>
          <el-option label="SHA-1" value="sha1"></el-option>
          <el-option label="SHA-256" value="sha256"></el-option>
          <el-option label="SHA-512" value="sha512"></el-option>
        </el-select>
        <el-button slot="append" @click="handleClickOK" class="btn-ok">
          计算
        </el-button>
      </el-input>
    </div>
  </div>
</template>

<script>
import md5 from "crypto-js/md5";
import sha1 from "crypto-js/sha1";
import sha256 from "crypto-js/sha256";
import sha512 from "crypto-js/sha512";

export default {
  name: "SimpleHash",

  data() {
    return {
      inputText: "",
      algorithm: "sha1",
      result: "",
    };
  },

  methods: {
    getAlgorithmFunc(alg) {
      let map = {};
      map["md5"] = md5;
      map["sha1"] = sha1;
      map["sha256"] = sha256;
      map["sha512"] = sha512;
      return map[alg];
    },

    handleClickOK() {
      let text = this.inputText;
      let hash = this.getAlgorithmFunc(this.algorithm);
      if (hash == null) {
        this.$alert("不支持的算法：" + this.algorithm);
        return;
      }
      let sum = hash(text);
      this.result = sum + "";
    },

    handleClickCompare() {},
  },
};
</script>

<style scoped>
.view-outer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.view-inner {
  padding: 20px;
  margin: 20px;

  box-shadow: 0px 0px 15px #888888;
  border-radius: 5px;
}

.el-select-algorithm {
  width: 120px;
}

.input-text {
  margin-bottom: 20px;
}

.btn-compare {
  margin-bottom: 20px;
  width: 100%;
}
</style>